<template>
  <div>
    <v-stepper :value="step" alt-labels class="remove-borde-background">
      <v-stepper-items class="remove-borde-background">
        <v-stepper-content step="1" class="remove-borde-background">
          <base-info @changeStep="step = $event"></base-info>
        </v-stepper-content>
        <v-stepper-content class="ma-0 pa-0" style="padding:0;" step="2">
          <form-cad></form-cad>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import logo from '@src/assets/images/logo/android-chrome-192x192.png'

export default {
  data() {
    return {
      step: 1,
      valid: false,
      logo,
    }
  },
  components: {
    BaseInfo: () => import('./info.vue'),
    FormCad: () => import('./formCad.vue'),
  },
  methods: {},
}
</script>

<style scoped lang="scss">
@import '@design';
</style>
